import { DateTime } from "luxon";
import { sortTeams } from "../utils/fftt/sortTeams";

export const ffttApiConfig = {
  equipe: {
    configParams: { numclu: "11310047", type: "A" },
    url: "https://apiv2.fftt.com/mobile/pxml/xml_equipe.php",
    parser: (result) => {
      const sortedTeams = sortTeams(result.liste.equipe);
      return sortedTeams;
    },
  },
  divisionResults: {
    configParams: { action: "", auto: 1 },
    url: "https://apiv2.fftt.com/mobile/pxml/xml_result_equ.php",
    parser: (result) => {
      return result;
    },
  },
  divisionRanking: {
    configParams: { action: "classement", auto: 1 },
    url: "https://apiv2.fftt.com/mobile/pxml/xml_result_equ.php",
    parser: (result) => {
      return result;
    },
  },
  resultDetails: {
    configParams: {},
    url: "https://apiv2.fftt.com/mobile/pxml/xml_chp_renc.php",
    parser: (result) => {
      return result;
    },
  },
  history: {
    configParams: {},
    url: "https://apiv2.fftt.com/mobile/pxml/xml_histo_classement.php",
    parser: (result) => {
      return result;
    },
  },
  player: {
    configParams: {},
    url: "https://apiv2.fftt.com/mobile/pxml/xml_licence_o.php",
    parser: (result) => {
      return result;
    },
  },
  indivRanking: {
    configParams: { action: "classement" },
    url: "https://apiv2.fftt.com/mobile/pxml/xml_result_indiv.php",
    parser: (result) => {
      return result.liste.classement;
    },
  },
  matchsSpid: {
    configParams: {},
    url: "https://apiv2.fftt.com/mobile/pxml/xml_partie.php",
    parser: (result) => {
      return result.liste !== "" ? result.liste.partie : [];
    },
  },
  matchsOff: {
    configParams: {},
    url: "https://apiv2.fftt.com/mobile/pxml/xml_partie_mysql.php",
    parser: (result) => {
      return result.liste !== "" ? result.liste.partie : [];
    },
  },
};
