export const menuLinks = [
  {
    key: "home",
    label: "Accueil",
    url: "/",
  },
  {
    key: "club",
    label: "Club",
    children: [
      {
        key: "club-1",
        label: "Présentation",
        pageLabel: "Le club",
        url: "/le-club",
      },
      {
        key: "club-2",
        label: "Lieu & Horaires",
        pageLabel: "Lieu & Horaires",
        url: "/lieu-horaires",
      },
      {
        key: "club-3",
        label: "Inscriptions & tarifs",
        pageLabel: "Inscriptions & tarifs",
        url: "/inscriptions-tarifs",
      },
      {
        key: "club-4",
        label: "Stages enfants",
        pageLabel: "Stages enfants",
        url: "/stages-enfants",
      },
      {
        key: "club-5",
        label: "Maillots & Vêtements",
        pageLabel: "Maillots & Vêtements",
        url: "/maillots-vetements",
      },
    ],
  },
  {
    key: "tournaments",
    label: "Compétitions",
    children: [
      {
        key: "tournaments-1",
        label: "Calendrier",
        pageLabel: "Calendrier compétitions",
        url: "/calendrier-competitions",
      },
      {
        key: "tournaments-2",
        label: "Championnat par équipes",
        pageLabel: "Championnat par équipes",
        url: "/championnat-par-equipes",
      },
      {
        key: "tournaments-3",
        label: "Critérium fédéral",
        pageLabel: "Critérium fédéral",
        url: "/criterium-federal",
      },
      {
        key: "tournaments-4",
        label: "Classement joueurs",
        pageLabel: "Classement joueurs",
        url: "/joueurs",
      },
    ],
  },
  {
    key: "partners",
    label: "Partenaires",
    children: [
      {
        key: "partners-1",
        label: "Ils nous soutiennent",
        pageLabel: "Ils nous soutiennent",
        url: "/partenaires",
      },
      {
        key: "partners-2",
        label: "Soutenir le club",
        pageLabel: "Soutenir le club",
        url: "/nous-soutenir",
      },
    ],
  },
  {
    key: "news",
    label: "Actualités",
    pageLabel: "Actualités",
    withFilters: true,
    url: "/actualites",
  },
  {
    key: "contact",
    label: "Contact",
    pageLabel: "Contact",
    url: "/contact",
  },
  {
    key: "Social Networks",
  },
];
