import { DateTime } from "luxon";

export const sortTeams = (data) => {
  // Calculer la phase actuelle
  const date = DateTime.now();
  const beginSecondPhase = DateTime.fromObject({
    year: date.year,
    month: 1,
    day: 7,
    hour: 0,
    minute: 0,
  });
  const endSecondPhase = DateTime.fromObject({
    year: date.year,
    month: 9,
    day: 7,
    hour: 0,
    minute: 0,
  });
  const currentPhase = date > beginSecondPhase && date < endSecondPhase ? 2 : 1;

  // Ajouter ou modifier les données pour chaque item
  const processedData = data
    .filter((item) => {
      // Conserver uniquement les équipes de la phase actuelle
      const itemPhase = parseInt(item.libequipe.slice(-1), 10);
      return itemPhase === currentPhase;
    })
    .map((item) => {
      // Extraire les informations et mettre à jour les données
      const urlParams = new URLSearchParams(item.liendivision);
      return {
        ...item,
        phase: parseInt(item.libequipe.slice(-1), 10),
        libequipe: item.libequipe.slice(0, item.libequipe.length - 10).trim(),
        poule: urlParams.get("cx_poule"),
        division: urlParams.get("D1"),
      };
    });

  // Grouper par libepr
  const groupedData = processedData.reduce((groups, item) => {
    if (!groups[item.libepr]) {
      groups[item.libepr] = [];
    }
    groups[item.libepr].push(item);
    return groups;
  }, {});

  // Trier les groupes et les équipes
  const sortedGroups = Object.keys(groupedData)
    .sort((a, b) => {
      // Prioriser "FED_Championnat de France par Equipes Masculin"
      if (a === "FED_Championnat de France par Equipes Masculin") return -1;
      if (b === "FED_Championnat de France par Equipes Masculin") return 1;
      return a.localeCompare(b);
    })
    .reduce((sorted, key) => {
      sorted[key] = groupedData[key].sort((teamA, teamB) => {
        // Trier les équipes par numéro croissant
        const numA = parseInt(teamA.libequipe.match(/\d+/), 10);
        const numB = parseInt(teamB.libequipe.match(/\d+/), 10);
        return numA - numB;
      });
      return sorted;
    }, {});

  return sortedGroups;
};
